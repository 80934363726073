import { ThemeProvider } from "@emotion/react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";

import Router from "presentation/Router";
import theme from "presentation/config/theme";
import DrawerMenuProvider from "presentation/contexts/DrawerMenu.context";

interface AppProps {}

const App: React.FunctionComponent<AppProps> = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DrawerMenuProvider>
          <Box className="App">
            {/* Good place for Snackbars and/or modals */}
            <Router />
          </Box>
        </DrawerMenuProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
