import { Avatar, Box, Icon, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import i18n from "i18next";
import { SuppliesSummaryPageLocale } from "../../../../../domain/interfaces/i18n/suppliesSummary.interface";
import { setDrawer } from "../../../../../store/slices/suppliesStateSlice/suppliesStateSlice";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import { getCityName } from "../../../../utils/city";

export const columns: GridColDef[] = [
  {
    field: "kitchen",
    headerName: i18n.t(SuppliesSummaryPageLocale.KITCHEN) ?? "cocina",
    width: 212,
    editable: false,
    renderCell: (params: GridRenderCellParams) => renderNameField(params),
  },
  {
    field: "totaloff",
    headerName: i18n.t(SuppliesSummaryPageLocale.TOTALOFF) ?? "total apagados",
    width: 308,
    editable: false,
    renderCell: (params: GridRenderCellParams) => RenderTotalOffField(params),
  },
  {
    field: "typeaa",
    headerName: i18n.t(SuppliesSummaryPageLocale.TYPEAA) ?? "tipo aa",
    width: 220,
    editable: false,
    renderCell: (params: GridRenderCellParams) =>
      renderTypeField(params, "typeaa"),
  },
  {
    field: "typea",
    headerName: i18n.t(SuppliesSummaryPageLocale.TYPEA) ?? "tipo a",
    width: 220,
    editable: false,
    renderCell: (params: GridRenderCellParams) =>
      renderTypeField(params, "typea"),
  },
  {
    field: "typeb",
    headerName: i18n.t(SuppliesSummaryPageLocale.TYPEB) ?? "tipo b",
    width: 220,
    editable: false,
    renderCell: (params: GridRenderCellParams) =>
      renderTypeField(params, "typeb"),
  },
  {
    field: "typec",
    headerName: i18n.t(SuppliesSummaryPageLocale.TYPEC) ?? "tipo c",
    width: 220,
    editable: false,
    renderCell: (params: GridRenderCellParams) =>
      renderTypeField(params, "typec"),
  },
];

export const renderNameField = (params: GridRenderCellParams | any) => {
  return (
    <Box display="flex" gap={2} sx={{ userSelect: "none" }}>
      <Avatar
        sx={{
          width: { xs: "30px", md: "40px" },
          height: { xs: "30px", md: "40px" },
        }}
        src={`/assets/images/country/${params.row.country}.svg`}
      />
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography
          textTransform="capitalize"
          fontWeight="700"
          color="#454B54"
          sx={{ fontSize: { xs: "12px", md: "14px" } }}
        >
          {params.value || params.row.id}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="300"
          color="text.secondary"
          sx={{ fontSize: { xs: "12px", md: "14px" } }}
        >
          {getCityName(params.row.city)}
        </Typography>
      </Box>
    </Box>
  );
};

export const RenderTotalOffField = (params: GridRenderCellParams | any) => {
  const dispatch = useAppDispatch();

  return (
    <Box display="flex" gap={2} sx={{ userSelect: "none" }}>
      <Icon sx={{ color: "#ED6C02", sx: 20 }}>error_outline</Icon>
      <Box display="flex" flexDirection="column" gap={0.5} width={188}>
        <Typography
          textTransform="capitalize"
          fontWeight="400"
          color="#7B7B7B"
          sx={{ fontSize: { xs: "16px", md: "14px" } }}
        >
          {params.value || params.row.id}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="700"
          color="#232323"
          sx={{ fontSize: { xs: "16px", md: "14px" } }}
        >
          {params.row.quantityoff}
        </Typography>
      </Box>
      <Icon
        sx={{ color: "#7B7B7B", sx: 20, cursor: "pointer" }}
        onClick={() => dispatch(setDrawer(true))}
      >
        add
      </Icon>
    </Box>
  );
};

export const renderTypeField = (
  params: GridRenderCellParams | any,
  type: string
) => {
  const status = params.value === "OK" ? "success" : "error";
  let quantity = 0;

  if (type === "typeaa") {
    quantity = params.row.quantityaa;
  } else if (type === "typea") {
    quantity = params.row.quantitya;
  } else if (type === "typeb") {
    quantity = params.row.quantityb;
  } else if (type === "typec") {
    quantity = params.row.quantityc;
  }
  return (
    <Box display="flex" gap={2} sx={{ userSelect: "none" }}>
      <Icon
        sx={{ color: status === "success" ? "#57CA00" : "#EF5350", sx: 20 }}
      >
        {status === "success" ? "check_circle_outline" : "highlight_off"}
      </Icon>
      <Box display="flex" flexDirection="column" gap={0.5} width={188}>
        <Typography
          textTransform="capitalize"
          fontWeight="400"
          color="#7B7B7B"
          sx={{ fontSize: { xs: "16px", md: "14px" } }}
        >
          {params.value || params.row.id}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="700"
          color="#232323"
          sx={{ fontSize: { xs: "16px", md: "14px" } }}
        >
          {quantity}
        </Typography>
      </Box>
    </Box>
  );
};
