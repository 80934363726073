import i18n from "i18next";
import { SummaryPageLocale } from "../../domain/interfaces/i18n/summary.interface";

export const timeHumanizeMinutes = (date: any) => {
  try {
    if (typeof date === "string") date = new Date(date);
    let delta = Math.round((+new Date() - date) / 1000);
    let minute = 60;
    let minutes;

    if (delta < 60) {
      minutes = 0;
    } else {
      minutes = Math.floor(delta / minute);
    }

    return minutes;
  } catch {
    return 0;
  }
};

export const timeHumanize = (date: any) => {
  try {
    if (typeof date == "string") date = new Date(date);
    var delta = Math.round((+new Date() - date) / 1000);

    var minute = 60,
      hour = minute * 60,
      day = hour * 24,
      week = day * 7;

    var fuzzy;

    if (delta < 30) {
      fuzzy = `${i18n.t(SummaryPageLocale.NOW)}.`;
    } else if (delta < minute) {
      fuzzy = `${delta} seg.`;
    } else if (delta < 2 * minute) {
      fuzzy = `1 ${i18n.t(SummaryPageLocale.MINUTES)}`;
    } else if (delta < hour) {
      fuzzy = `${Math.floor(delta / minute)} ${i18n.t(
        SummaryPageLocale.MINUTES
      )}`; //plurar
    } else if (Math.floor(delta / hour) === 1) {
      fuzzy = "1 hora.";
    } else if (delta < day) {
      fuzzy = Math.floor(delta / hour) + " hrs";
    } else if (delta < day * 2) {
      fuzzy = "yesterday";
    } else {
      fuzzy = `${Math.floor(454953 / day)} ${i18n.t(SummaryPageLocale.DAYS)}`;
    }
    return fuzzy;
  } catch {
    return "~";
  }
};

export const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  let minutes: any = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const formatDateNormal = (date: Date) => {
  const monthsMap = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Setiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  let days: string | number = date.getDate();
  let month: string = monthsMap[date.getMonth()];
  days = days < 10 ? "0" + days : days;
  let strTime = `${days}/${month}/${date.getFullYear()}`;
  return strTime;
};

export const diffHour = (date: any): Number => {
  let delta: any = Math.round((+new Date() - date) / 1000);
  const hour = 60 * 60;
  return Math.floor(delta / hour);
};
