import { ResourceLanguage } from "i18next";
import { AlarmsLocale } from "../../../domain/interfaces/i18n/alarms.interface";
import { DashboardUptimeLocale } from "../../../domain/interfaces/i18n/dashboards.interface";
import { FilterLocale } from "../../../domain/interfaces/i18n/filters.interface";
import { OrderPageLocale } from "../../../domain/interfaces/i18n/order.interface";
import { SummaryPageLocale } from "../../../domain/interfaces/i18n/summary.interface";
import { SuppliesSummaryPageLocale } from "../../../domain/interfaces/i18n/suppliesSummary.interface";

const ptLocale: ResourceLanguage = {
  [SummaryPageLocale.TITLE]: "Resumo de Lojas",
  [SummaryPageLocale.DESCRIPTION]:
    "Personalize os dados que deseja visualizar na tabela",
  [SummaryPageLocale.ATENTION]: "Atenção",
  [SummaryPageLocale.OK]: "Ok",

  [SummaryPageLocale.KITCHEN]: "Cozinha",
  [SummaryPageLocale.BRANDS]: "Marcas",
  [SummaryPageLocale.POLYGONS]: "Polígonos",
  [SummaryPageLocale.NO_BRANDS_AVAILABLE]:
    "Nenhuma marca disponível nesta plataforma",
  [SummaryPageLocale.NO_BRANDS_COVERAGE]: "Sem informações de cobertura",
  [SummaryPageLocale.WITHOUT_INFORMATION]: "Sem informações",

  [SummaryPageLocale.BUTTON_EDIT_COLUMNS]: "Editar colunas",
  [FilterLocale.BUTTON]: "Filtros",
  [FilterLocale.TITLE]: "Filtros",
  [FilterLocale.ALL]: "Todos",
  [FilterLocale.SEARCH_TEXT]: "Busque por cidade ou cozinha",
  [FilterLocale.ALL_CITIES]: "Todas as cidades",
  [FilterLocale.ALL_KITCHENS]: "Todas as cozinhas",
  [FilterLocale.COUNTRY]: "Países",
  [FilterLocale.CITY]: "Cidades",
  [FilterLocale.KITCHEN]: "Cozinha",

  [SummaryPageLocale.DRAWER_COLUMN_TITLE]: "Editar colunas",
  [SummaryPageLocale.DRAWER_COLUMN_DESCRIPTION]:
    "Oculte ou adicione colunas na tabela",
  [SummaryPageLocale.DRAWER_COLUMN_CARD_TITLE]: "Configuração de colunas",
  [SummaryPageLocale.DRAWER_COLUMN_CARD_DESCRIPTION]:
    "Escolha as colunas que deseja ocultar",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_IN]: "Marcas em",
  [SummaryPageLocale.DRAWER_COLUMN_COVERAGE_IN]: "Cobertura",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_DESCRIPTION_COVERAGE]:
    "Marcas ligadas e desligadas",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_DESCRIPTION_POLYGON]:
    "Cobertura de polígonos das marcas",

  [SummaryPageLocale.SHOPS]: "Lojas",
  [SummaryPageLocale.BRAND]: "Marca",
  [SummaryPageLocale.LAST_UPDATE]: "Última atualização",
  [SummaryPageLocale.IN_SCHEDULE]: "Dentro do horário",
  [SummaryPageLocale.OUT_SCHEDULE]: "Fora do horário",
  [SummaryPageLocale.OFF]: "Desligadas",
  [SummaryPageLocale.ON]: "Ligadas",
  [SummaryPageLocale.SHOULD_OFF]: "Deveria estar desligada",
  [SummaryPageLocale.SHOULD_OFF_BY_SCHEDULE]:
    "Deveria estar desligada pelo horário",
  [SummaryPageLocale.SHOULD_ON]: "Deveria estar ligada",
  [SummaryPageLocale.SHOULD_ON_BY_SCHEDULE]:
    "Deveria estar ligada pelo horário",
  [SummaryPageLocale.SCHEDULE_DEFINED]: "Horário definido:",
  [SummaryPageLocale.MOTIVE]: "Motivo:",
  [SummaryPageLocale.AGO]: "Há",
  [SummaryPageLocale.DAYS]: "dias",
  [SummaryPageLocale.NOW]: "Agora",
  [SummaryPageLocale.MINUTES]: "min",

  [OrderPageLocale.TITLE]: "Pedidos Ativos",
  [OrderPageLocale.DESCRIPTION]: "Veja os dados ao vivo dos pedidos na cozinha",
  [OrderPageLocale.COOKING]: "Cozinhando",
  [OrderPageLocale.PACKING]: "Empacotando",
  [OrderPageLocale.TO_DELIVER]: "Para entregar",
  [OrderPageLocale.ORDER_RT]: "Com entregador",
  [OrderPageLocale.ORDER_WITHOUT_RT]: "Sem entregador",
  [OrderPageLocale.ORDER_LATER]: "Pedidos atrasados",
  [OrderPageLocale.PEOPLE_IN_KITCHEN_RESUMEN]: "Pessoas na cozinha",
  [OrderPageLocale.PEOPLE_IN_KITCHEN]: "Equipe na cozinha vs realizado",

  [OrderPageLocale.KITCHEN]: "Cozinha",
  [OrderPageLocale.ORDER_ACTIVE]: "Pedidos ativos",
  [OrderPageLocale.LAST_UPDATE]: "Última atualização",
  [OrderPageLocale.IN_STATION]: "Na estação",
  [OrderPageLocale.IN_QUEUE]: "Na fila e cozinhando",
  [OrderPageLocale.OK]: "Ok",
  [OrderPageLocale.ATENTION]: "Atenção",

  [OrderPageLocale.PLATFORM]: "PLATAFORMA",
  [OrderPageLocale.BRAND]: "MARCA",
  [OrderPageLocale.TOTAL_ORDERS]: "TOTAL DE PEDIDOS",
  [OrderPageLocale.MAIN_PLATE]: "PRATOS PRINCIPAIS",

  [OrderPageLocale.DRAWER_ORDERS_TITLE]: "Pedidos Ativos",
  [OrderPageLocale.DRAWER_ORDERS_DESCRIPTION]:
    "Visualize os pedidos na estação",

  [OrderPageLocale.DRAWER_DELIVER_TITLE]: "Para Entregar",
  [OrderPageLocale.DRAWER_DELIVER_DESCRIPTION]:
    "Visualize os pedidos prontos e ainda não entregues",

  [OrderPageLocale.PERSONAL]: "EQUIPE",
  [OrderPageLocale.SCHEDULE_IN]: "ENTROU ÀS",
  [OrderPageLocale.DATE_IN]: "Data de entrada",
  [OrderPageLocale.HOURS_IN_KITCHEN]: "Horas na cozinha",
  [OrderPageLocale.DRAWER_PEOPLE_TITLE]: "Equipe na cozinha",
  [OrderPageLocale.DRAWER_PEOPLE_DESCRIPTION]:
    "Visualize as pessoas ativas no turno",

  [OrderPageLocale.PEOPLE_CARD_TITLE]: "Equipe na cozinha",
  [OrderPageLocale.PEOPLE_CARD_DESCRIPTION]:
    "Cozinheiros ou chefes de cozinha com turnos ativos",
  [OrderPageLocale.PEOPLE_CARD_CORP_TITLE]: "Equipe corporativa",
  [OrderPageLocale.PEOPLE_CARD_CORP_DESCRIPTION]:
    "Qualidade, Operações, etc. com turno ativo na cozinha",

  [AlarmsLocale.TITLE]: "Alarmes",
  [AlarmsLocale.SUBTITLE]:
    "Inscreva-se nos alarmes que deseja receber sobre eventos nas cozinhas para ficar atualizado",
  [AlarmsLocale.FORM_TITLE]: "Criar um novo alarme",
  [AlarmsLocale.FORM_EVENT]: "Evento*",
  [AlarmsLocale.FORM_EVENT_LABEL]:
    "Evento pelo qual você receberá a notificação",
  [AlarmsLocale.FORM_EVENT_PLACEHOLDER]: "Escolha o evento",
  [AlarmsLocale.EVENT_ON]: "Ligados e desligados",
  [AlarmsLocale.EVENT_POLYGONS]: "Polígonos",
  [AlarmsLocale.FORM_CHANNEL]: "Canal*",
  [AlarmsLocale.FORM_CHANNEL_LABEL]:
    "Somente WhatsApp está disponível, mais canais em breve",
  [AlarmsLocale.FORM_COUNTRY]: "País",
  [AlarmsLocale.FORM_COUNTRY_LABEL]: "",
  [AlarmsLocale.FORM_COUNTRY_PLACEHOLDER]: "Escolha o país",
  [AlarmsLocale.FORM_CITY]: "Cidade",
  [AlarmsLocale.FORM_CITY_LABEL]: "",
  [AlarmsLocale.FORM_CITY_PLACEHOLDER]: "Escolha a cidade",
  [AlarmsLocale.FORM_BRAND]: "Marcas",
  [AlarmsLocale.FORM_BRAND_LABEL]: "",
  [AlarmsLocale.FORM_BRAND_PLACEHOLDER]: "Escolha a marca",
  [AlarmsLocale.FORM_PHONE_NUMBER]: "Número de telefone*",
  [AlarmsLocale.FORM_PHONE_NUMBER_LABEL]:
    "Número para o qual enviaremos as notificações",
  [AlarmsLocale.FORM_PHONE_NUMBER_PLACEHOLDER]: "Número de telefone",
  [AlarmsLocale.FORM_SUBMIT]: "CRIAR ALARME",
  [AlarmsLocale.ALL]: "Todos",

  [AlarmsLocale.NEW_ALARM_TEXT]:
    "Clique em 'Criar um novo alarme' para começar a receber alertas sobre o estado das suas cozinhas",
  [AlarmsLocale.NEW_ALARM_BUTTON]: "CRIAR MEU PRIMEIRO ALARME",
  [AlarmsLocale.NEW_ALARM_SUCCESS]: "Alarme criado com sucesso",
  [AlarmsLocale.CONTINUE]: "Continuar",
  [AlarmsLocale.PHONE]: "Telefone",
  [AlarmsLocale.ACTIONS]: "Ações",
  [AlarmsLocale.PREV_EVENTS]: "Criados anteriormente",
  [AlarmsLocale.ACTIVE]: "Ativo",
  [AlarmsLocale.RECEIVED_ALARM_COPY1]: "Você receberá notificações em",
  [AlarmsLocale.RECEIVED_ALARM_COPY2]: "sobre",
  [AlarmsLocale.RECEIVED_ALARM_COPY3]: "de",
  [AlarmsLocale.RECEIVED_ALARM_COPY4]: "em",
  [AlarmsLocale.RECEIVED_ALARM_COPY5]: "para",

  [DashboardUptimeLocale.TITLE]: "Dashboard Uptime",
  [DashboardUptimeLocale.SUBTITLE]: "cópia do dashboard uptime...",

  [SuppliesSummaryPageLocale.TITLE]: "Resumo de Lojas",
  [SuppliesSummaryPageLocale.DESCRIPTION]:
    "Personalize os dados que deseja visualizar na tabela",
  [SuppliesSummaryPageLocale.TOTALOFF]: "Total desligados",
  [SuppliesSummaryPageLocale.TYPEAA]: "Tipo AA",
  [SuppliesSummaryPageLocale.TYPEA]: "Tipo A",
  [SuppliesSummaryPageLocale.TYPEB]: "Tipo B",
  [SuppliesSummaryPageLocale.TYPEC]: "Tipo C",
  [SuppliesSummaryPageLocale.KITCHEN]: "Cozinha",
};

export default ptLocale;
