import config from "config.provider";
import { Session } from "../../domain/entities/Session";
import { getCookie, removeCookie } from "./cookie";

export class SessionNotFoundError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, SessionNotFoundError.prototype);
  }
}

export const sessionValidator = (session: Session) => {
  const {
    kitchenDisplay: { url: kds },
    kitchenInventory: { url },
  } = config;
  const redirect = `${kds}/login?redirect=${url}${window.location.pathname}`;
  if (!session) {
    window.location.href = redirect;
    throw new SessionNotFoundError("Cookie session not found");
  }
  const { userId, token } = session;
  if (!userId || !token) {
    window.location.href = redirect;
    throw new SessionNotFoundError("Cookie session not found");
  }
};

export const getSession = (): Session => {
  const cookieUserId = getCookie("userId");
  const cookieLoginToken = getCookie("loginToken");
  const cookieCompanyId = getCookie("companyId");
  const cookieRole = getCookie("role");
  const authorization = getCookie("authorization");

  const session: Session = {
    userId: cookieUserId ?? "",
    token: cookieLoginToken ?? "",
    role: cookieRole ?? "",
    companyId: cookieCompanyId ?? "fdgy",
    authorization: authorization ?? "",
  };

  sessionValidator(session);

  return session;
};

export const removeSession = (): void => {
  const cookieUserId = getCookie("userId");
  if (cookieUserId) {
    removeCookie("userId");
  }
  const cookieLoginToken = getCookie("loginToken");
  if (cookieLoginToken) {
    removeCookie("loginToken");
  }
};
