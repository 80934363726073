import config from "config.provider";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { setUserIdAmplitude } from "../../common/utils/amplitude";
import { getCookie } from "../../common/utils/cookie";
import { getSession } from "../../store/slices/sessionSlice";
import { getUser } from "../../store/slices/user.slice";
import { AsyncState } from "../../store/types";
import useAppDispatch from "../hooks/useAppDispatch";
import useAppSelector from "../hooks/useAppSelector";

interface ProtectedRoute {
  children: React.ReactNode;
}

const ProtectedRoutes = (props: ProtectedRoute) => {
  const { children } = props;

  const location = useLocation();

  const [userToken, setUserToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const sessionStatus = useAppSelector((state) => state.session.status);
  const session = useAppSelector((state) => state.session.session);

  useEffect(() => {
    setLoading(true);
    const cookieUserId = getCookie("userId");
    const cookieLoginToken = getCookie("loginToken");

    if (cookieUserId && cookieLoginToken) {
      setUserId(cookieUserId);
      setUserToken(cookieLoginToken);
      setUserIdAmplitude(cookieUserId);
    } else {
      window.location.href = `${config.kitchenDisplay.url}/login?redirect=${config.kitchenMonitor.url}${location.pathname}`;
    }
    setLoading(false);
  }, [location.pathname]);

  useEffect(() => {
    if (sessionStatus === AsyncState.IDLE) {
      dispatch(getSession());
    }
  }, [sessionStatus, dispatch]);
  useEffect(() => {
    if (session) {
      dispatch(getUser(session));
    }
  }, [session, dispatch]);

  return (
    <>
      {loading && "Cargando.."}
      {userToken && userId && children}
    </>
  );
};

export default ProtectedRoutes;
