import { KOSEnvironment, KOSTopbar } from "@foodology-co/alejandria";
import config from "config.provider";
import { DrawerMenuContext } from "presentation/contexts/DrawerMenu.context";
import React from "react";
import { getUserLogOut } from "../../gateways/http/user";
import useAppSelector from "../hooks/useAppSelector";

interface AppBarProps {}

const AppBar: React.FunctionComponent<AppBarProps> = () => {
  const drawerMenu = React.useContext(DrawerMenuContext);
  const user = useAppSelector((state) => state.user);
  const session = useAppSelector((state) => state.session.session);
  const title = useAppSelector((state) => state.screen.title);

  return (
    <KOSTopbar
      title={title ?? ""}
      environment={config.env as KOSEnvironment}
      hamburgerMenu={{
        open: drawerMenu.open,
        onClick: drawerMenu.toggle,
      }}
      user={{
        name: user.user?.profile?.name ?? "",
        avatarUrl: user.user?.profileImage,
        companyId: session?.companyId ?? "fdgy",
      }}
      accountMenu={{
        helpCenterLink:
          "https://foodology.notion.site/Centro-de-ayuda-para-cocinas-f49c4322877e428f907652f9b3d2783c",
        logOut: {
          redirect: `${config.kitchenDisplay.url}/login`,
          onClick: getUserLogOut,
        },
      }}
    />
  );
};

export default AppBar;
