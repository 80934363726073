import { ApiVersion, getHttp } from "../../common/utils/http";
import configProvider from "../../config.provider";
import { Brand } from "./Brand";

export const allBrands = async (): Promise<Brand[]> => {
  try {
    const response = await getHttp(
      configProvider.bistroUrl,
      ApiVersion.V1,
      `brand/all`
    );
    const data = await response.json();
    return data.data as Brand[];
  } catch (ex: any) {
    console.error(`Error getting all brands | ${ex.message}`);
    return [] as Brand[];
  }
};

export const brandsByCountry = async (country: string): Promise<Brand[]> => {
  try {
    const response = await getHttp(
      configProvider.bistroUrl,
      ApiVersion.V1,
      `brand/country/${country}`
    );
    const data = await response.json();
    return data.data as Brand[];
  } catch (ex: any) {
    console.error(`Error getting brands by country | ${ex.message}`);
    return [] as Brand[];
  }
};
