export enum AlarmsLocale {
  TITLE = "alarms.title",
  SUBTITLE = "alarms.subtitle",
  FORM_TITLE = "alarms.form.title",
  FORM_EVENT = "alarms.form.event",
  FORM_EVENT_LABEL = "alarms.form.label",
  FORM_EVENT_PLACEHOLDER = "alarms.event.placeholder",
  EVENT_ON = "alarms.event.on",
  EVENT_POLYGONS = "alarms.event.polygons",
  FORM_CHANNEL = "alarms.form.channel",
  FORM_CHANNEL_LABEL = "alarms.form.channel.label",
  FORM_COUNTRY = "alarms.form.country",
  FORM_COUNTRY_LABEL = "alarms.form.country.label",
  FORM_COUNTRY_PLACEHOLDER = "alarms.form.country.placeholder",
  FORM_CITY = "alarms.form.city",
  FORM_CITY_LABEL = "alarms.form.city.label",
  FORM_CITY_PLACEHOLDER = "alarms.form.city.placeholder",
  FORM_BRAND = "alarms.form.brand",
  FORM_BRAND_LABEL = "alarms.brand.label",
  FORM_BRAND_PLACEHOLDER = "alarms.brand.placeholder",
  FORM_PHONE_NUMBER = "alarms.form.phone_number",
  FORM_PHONE_NUMBER_LABEL = "alarms.form.phone_number.label",
  FORM_PHONE_NUMBER_PLACEHOLDER = "alarms.phone_number.placeholder",
  FORM_SUBMIT = "alarms.form.submit",

  NEW_ALARM_TEXT = "alarms.new.text",
  NEW_ALARM_BUTTON = "alarms.new.button",
  NEW_ALARM_SUCCESS = "alarms.new.success",
  CONTINUE = "alarms.continue",
  ALL = "alarms.all",
  PHONE = "alarms.phone",
  ACTIONS = "alarms.actions",
  PREV_EVENTS = "alarms.prev_events",
  ACTIVE = "alarms.active",
  RECEIVED_ALARM_COPY1 = "alarms.copy.1",
  RECEIVED_ALARM_COPY2 = "alarms.copy.2",
  RECEIVED_ALARM_COPY3 = "alarms.copy.3",
  RECEIVED_ALARM_COPY4 = "alarms.copy.4",
  RECEIVED_ALARM_COPY5 = "alarms.copy.5",
}
