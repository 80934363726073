import { KOSDrawer } from "@foodology-co/alejandria";
import { Box, Button, Icon, Typography } from "@mui/material";
import React from "react";
import { Supply } from "../../../../../domain/entities/Supplies.entity";
import { setDrawer } from "../../../../../store/slices/suppliesStateSlice/suppliesStateSlice";
import {
  SupplyStateDrawers,
  supplies,
} from "../../../../../store/slices/suppliesStateSlice/type";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import AAIcon from "../../utils/icons/AAIcon";
import AIcon from "../../utils/icons/AIcon";
import BIcon from "../../utils/icons/BIcon";
import CIcon from "../../utils/icons/CIcon";
import SuppliesOffDetailsContent from "./SuppliesOffDetailsContent";
import SuppliesOffDetailsHeader from "./SuppliesOffDetailsHeader";

interface DrawerSuppliesOffProps {}

const DrawerSuppliesOff: React.FC<DrawerSuppliesOffProps> = () => {
  const { kitchen, open } = useAppSelector(
    (state) => state.supplySlice.drawers[SupplyStateDrawers.SUPPLIES]
  );

  const dispatch = useAppDispatch();

  const closeDrawer = () => {
    dispatch(setDrawer(false));
  };

  const getTypeSupplies = (type: string) => {
    const filteredSupplies = supplies.filter(
      (supply) => supply.suppliesOff[0].type === type
    );
    const filteredRows = filteredSupplies.map((supply) => ({
      id: supply.id,
      sku: supply.suppliesOff[0].sku,
      supply: supply.suppliesOff[0].supply,
      type: supply.suppliesOff[0].type,
      inventory: supply.suppliesOff[0].inventory,
      kitchen: supply.kitchen,
      suppliesOff: supply.suppliesOff,
      city: supply.city,
      country: supply.country,
    }));
    return filteredRows as Supply[];
  };

  return (
    <KOSDrawer
      color="#011A5B"
      icon="no_food"
      iconType="icon"
      onClose={closeDrawer}
      open={open}
      title={`Total Apagados ${kitchen}`}
      subTitle="Ultima actualización: ahora"
      total={supplies.length}
      zIndex={14}
    >
      <Box>
        <Box sx={{ display: "flex", my: 2, mx: 1 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "4px",
              width: "100%",
              height: "48px",
              color: "primary.main",
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            <Icon sx={{ width: "24px", height: "24px" }}>open_in_new</Icon>
            <Typography
              sx={{
                ml: 1,
                textTransform: "uppercase",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "16px",
              }}
            >
              Ir a apagados
            </Typography>
          </Button>
        </Box>
        {getTypeSupplies("AA").length > 0 && (
          <>
            <SuppliesOffDetailsHeader
              titleHeader="Tipo AA"
              bgcolor="#E65100"
              count={getTypeSupplies("AA").length}
            >
              <AAIcon color="#fff" width="45px" />
            </SuppliesOffDetailsHeader>
            <SuppliesOffDetailsContent
              supplies={getTypeSupplies("AA")}
              rows={getTypeSupplies("AA")}
            />
          </>
        )}

        {getTypeSupplies("A").length > 0 && (
          <>
            <SuppliesOffDetailsHeader
              titleHeader="Tipo A"
              bgcolor="#ED6C02"
              count={getTypeSupplies("A").length}
            >
              <AIcon color="#fff" width="30px" />
            </SuppliesOffDetailsHeader>
            <SuppliesOffDetailsContent
              supplies={getTypeSupplies("A")}
              rows={getTypeSupplies("A")}
            />
          </>
        )}

        {getTypeSupplies("B").length > 0 && (
          <>
            <SuppliesOffDetailsHeader
              titleHeader="Tipo B"
              bgcolor="#01579B"
              count={getTypeSupplies("B").length}
            >
              <BIcon color="#fff" width="23px" />
            </SuppliesOffDetailsHeader>
            <SuppliesOffDetailsContent
              supplies={getTypeSupplies("B")}
              rows={getTypeSupplies("B")}
            />
          </>
        )}

        {getTypeSupplies("C").length > 0 && (
          <>
            <SuppliesOffDetailsHeader
              titleHeader="Tipo C"
              bgcolor="#0288D1"
              count={getTypeSupplies("C").length}
            >
              <CIcon color="#fff" width="25px" />
            </SuppliesOffDetailsHeader>
            <SuppliesOffDetailsContent
              supplies={getTypeSupplies("C")}
              rows={getTypeSupplies("C")}
            />
          </>
        )}
      </Box>
    </KOSDrawer>
  );
};

export default DrawerSuppliesOff;
