import { ResourceLanguage } from "i18next";
import { AlarmsLocale } from "../../../domain/interfaces/i18n/alarms.interface";
import { DashboardUptimeLocale } from "../../../domain/interfaces/i18n/dashboards.interface";
import { FilterLocale } from "../../../domain/interfaces/i18n/filters.interface";
import { OrderPageLocale } from "../../../domain/interfaces/i18n/order.interface";
import { SummaryPageLocale } from "../../../domain/interfaces/i18n/summary.interface";
import { SuppliesSummaryPageLocale } from "../../../domain/interfaces/i18n/suppliesSummary.interface";

const enLocale: ResourceLanguage = {
  [SummaryPageLocale.TITLE]: "Store Summary",
  [SummaryPageLocale.DESCRIPTION]:
    "Customize the data you want to display in the table",
  [SummaryPageLocale.ATENTION]: "Attention",
  [SummaryPageLocale.OK]: "Ok",

  [SummaryPageLocale.KITCHEN]: "Kitchen",
  [SummaryPageLocale.BRANDS]: "Brands",
  [SummaryPageLocale.POLYGONS]: "Polygons",
  [SummaryPageLocale.NO_BRANDS_AVAILABLE]:
    "No brands available on this platform",
  [SummaryPageLocale.NO_BRANDS_COVERAGE]: "No coverage information",
  [SummaryPageLocale.WITHOUT_INFORMATION]: "Without information",

  [SummaryPageLocale.BUTTON_EDIT_COLUMNS]: "Edit columns",
  [FilterLocale.BUTTON]: "Filters",
  [FilterLocale.TITLE]: "Filters",
  [FilterLocale.ALL]: "All",
  [FilterLocale.SEARCH_TEXT]: "Search by city or kitchen",
  [FilterLocale.ALL_CITIES]: "All cities",
  [FilterLocale.ALL_KITCHENS]: "All kitchens",
  [FilterLocale.COUNTRY]: "Countries",
  [FilterLocale.CITY]: "Cities",
  [FilterLocale.KITCHEN]: "Kitchen",

  [SummaryPageLocale.DRAWER_COLUMN_TITLE]: "Edit columns",
  [SummaryPageLocale.DRAWER_COLUMN_DESCRIPTION]:
    "Hide or add columns in the table",
  [SummaryPageLocale.DRAWER_COLUMN_CARD_TITLE]: "Column settings",
  [SummaryPageLocale.DRAWER_COLUMN_CARD_DESCRIPTION]:
    "Choose the columns you want to hide",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_IN]: "Brands in",
  [SummaryPageLocale.DRAWER_COLUMN_COVERAGE_IN]: "Coverage",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_DESCRIPTION_COVERAGE]:
    "Brands turned on and off",
  [SummaryPageLocale.DRAWER_COLUMN_BRAND_DESCRIPTION_POLYGON]:
    "Brand polygon coverage",

  [SummaryPageLocale.SHOPS]: "Stores",
  [SummaryPageLocale.BRAND]: "Brand",
  [SummaryPageLocale.LAST_UPDATE]: "Last update",
  [SummaryPageLocale.IN_SCHEDULE]: "Within schedule",
  [SummaryPageLocale.OUT_SCHEDULE]: "Out of schedule",
  [SummaryPageLocale.OFF]: "Off",
  [SummaryPageLocale.ON]: "On",
  [SummaryPageLocale.SHOULD_OFF]: "Should be off",
  [SummaryPageLocale.SHOULD_OFF_BY_SCHEDULE]: "Should be off due to schedule",
  [SummaryPageLocale.SHOULD_ON]: "Should be on",
  [SummaryPageLocale.SHOULD_ON_BY_SCHEDULE]: "Should be on due to schedule",
  [SummaryPageLocale.SCHEDULE_DEFINED]: "Defined schedule:",
  [SummaryPageLocale.MOTIVE]: "Reason:",
  [SummaryPageLocale.AGO]: "Ago",
  [SummaryPageLocale.DAYS]: "days",
  [SummaryPageLocale.NOW]: "Now",
  [SummaryPageLocale.MINUTES]: "min",

  [OrderPageLocale.TITLE]: "Active Orders",
  [OrderPageLocale.DESCRIPTION]: "View live data of orders in the kitchen",
  [OrderPageLocale.COOKING]: "Cooking",
  [OrderPageLocale.PACKING]: "Packing",
  [OrderPageLocale.TO_DELIVER]: "To deliver",
  [OrderPageLocale.ORDER_RT]: "With delivery person",
  [OrderPageLocale.ORDER_WITHOUT_RT]: "Without delivery person",
  [OrderPageLocale.ORDER_LATER]: "Late orders",
  [OrderPageLocale.PEOPLE_IN_KITCHEN_RESUMEN]: "People in the kitchen",
  [OrderPageLocale.PEOPLE_IN_KITCHEN]: "Staff in kitchen vs completed",

  [OrderPageLocale.KITCHEN]: "Kitchen",
  [OrderPageLocale.ORDER_ACTIVE]: "Active orders",
  [OrderPageLocale.LAST_UPDATE]: "Last update",
  [OrderPageLocale.IN_STATION]: "In station",
  [OrderPageLocale.IN_QUEUE]: "In queue and cooking",
  [OrderPageLocale.OK]: "Ok",
  [OrderPageLocale.ATENTION]: "Attention",

  [OrderPageLocale.PLATFORM]: "PLATFORM",
  [OrderPageLocale.BRAND]: "BRAND",
  [OrderPageLocale.TOTAL_ORDERS]: "TOTAL ORDERS",
  [OrderPageLocale.MAIN_PLATE]: "MAIN COURSES",

  [OrderPageLocale.DRAWER_ORDERS_TITLE]: "Active Orders",
  [OrderPageLocale.DRAWER_ORDERS_DESCRIPTION]: "View orders in the station",

  [OrderPageLocale.DRAWER_DELIVER_TITLE]: "To Deliver",
  [OrderPageLocale.DRAWER_DELIVER_DESCRIPTION]:
    "View orders that are ready but not yet delivered",

  [OrderPageLocale.PERSONAL]: "PERSONNEL",
  [OrderPageLocale.SCHEDULE_IN]: "ENTERED AT",
  [OrderPageLocale.DATE_IN]: "Entry date",
  [OrderPageLocale.HOURS_IN_KITCHEN]: "Hours in kitchen",
  [OrderPageLocale.DRAWER_PEOPLE_TITLE]: "Kitchen staff",
  [OrderPageLocale.DRAWER_PEOPLE_DESCRIPTION]:
    "View active staff during the shift",

  [OrderPageLocale.PEOPLE_CARD_TITLE]: "Kitchen staff",
  [OrderPageLocale.PEOPLE_CARD_DESCRIPTION]:
    "Chefs or head cooks with active shifts",
  [OrderPageLocale.PEOPLE_CARD_CORP_TITLE]: "Corporate staff",
  [OrderPageLocale.PEOPLE_CARD_CORP_DESCRIPTION]:
    "Quality, Ops, etc., with an active shift in the kitchen",

  [AlarmsLocale.TITLE]: "Alarms",
  [AlarmsLocale.SUBTITLE]:
    "Subscribe to the alarms you want to receive about kitchen events to stay up to date",
  [AlarmsLocale.FORM_TITLE]: "Create a new alarm",
  [AlarmsLocale.FORM_EVENT]: "Event*",
  [AlarmsLocale.FORM_EVENT_LABEL]:
    "Event for which you will receive the notification",
  [AlarmsLocale.FORM_EVENT_PLACEHOLDER]: "Choose the event",
  [AlarmsLocale.EVENT_ON]: "Turned on and off",
  [AlarmsLocale.EVENT_POLYGONS]: "Polygons",
  [AlarmsLocale.FORM_CHANNEL]: "Channel*",
  [AlarmsLocale.FORM_CHANNEL_LABEL]:
    "Only WhatsApp is available, more channels coming soon",
  [AlarmsLocale.FORM_COUNTRY]: "Country",
  [AlarmsLocale.FORM_COUNTRY_LABEL]: "",
  [AlarmsLocale.FORM_COUNTRY_PLACEHOLDER]: "Choose the country",
  [AlarmsLocale.FORM_CITY]: "City",
  [AlarmsLocale.FORM_CITY_LABEL]: "",
  [AlarmsLocale.FORM_CITY_PLACEHOLDER]: "Choose the city",
  [AlarmsLocale.FORM_BRAND]: "Brands",
  [AlarmsLocale.FORM_BRAND_LABEL]: "",
  [AlarmsLocale.FORM_BRAND_PLACEHOLDER]: "Choose the brand",
  [AlarmsLocale.FORM_PHONE_NUMBER]: "Phone number*",
  [AlarmsLocale.FORM_PHONE_NUMBER_LABEL]:
    "Phone number to which we will send notifications",
  [AlarmsLocale.FORM_PHONE_NUMBER_PLACEHOLDER]: "Phone number",
  [AlarmsLocale.FORM_SUBMIT]: "CREATE ALARM",
  [AlarmsLocale.ALL]: "All",

  [AlarmsLocale.NEW_ALARM_TEXT]:
    "Click on 'Create a new alarm' to start receiving alerts about the state of your kitchens",
  [AlarmsLocale.NEW_ALARM_BUTTON]: "CREATE MY FIRST ALARM",
  [AlarmsLocale.NEW_ALARM_SUCCESS]: "Alarm created successfully",
  [AlarmsLocale.CONTINUE]: "Continue",
  [AlarmsLocale.PHONE]: "Phone",
  [AlarmsLocale.ACTIONS]: "Actions",
  [AlarmsLocale.PREV_EVENTS]: "Previously created",
  [AlarmsLocale.ACTIVE]: "Active",
  [AlarmsLocale.RECEIVED_ALARM_COPY1]: "You will receive notifications on",
  [AlarmsLocale.RECEIVED_ALARM_COPY2]: "about",
  [AlarmsLocale.RECEIVED_ALARM_COPY3]: "from",
  [AlarmsLocale.RECEIVED_ALARM_COPY4]: "in",
  [AlarmsLocale.RECEIVED_ALARM_COPY5]: "to",

  [DashboardUptimeLocale.TITLE]: "Dashboard Uptime",
  [DashboardUptimeLocale.SUBTITLE]: "copy dashboard uptime...",

  [SuppliesSummaryPageLocale.TITLE]: "Store Summary",
  [SuppliesSummaryPageLocale.DESCRIPTION]:
    "Customize the data you want to display in the table",
  [SuppliesSummaryPageLocale.TOTALOFF]: "Total off",
  [SuppliesSummaryPageLocale.TYPEAA]: "Type AA",
  [SuppliesSummaryPageLocale.TYPEA]: "Type A",
  [SuppliesSummaryPageLocale.TYPEB]: "Type B",
  [SuppliesSummaryPageLocale.TYPEC]: "Type C",
  [SuppliesSummaryPageLocale.KITCHEN]: "Kitchen",
};

export default enLocale;
