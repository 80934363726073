import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { KOSEnvironment, KOSFavIcon } from "@foodology-co/alejandria";
import config from "config.provider";
import AppLayout from "presentation/components/AppLayout";
import ProtectedRoutes from "presentation/components/ProtectedRoutes";
import useAppSelector from "./hooks/useAppSelector";
import FormNPS from "./pages/callback/FormNPS";
import LoadingPage from "./pages/loading";
import MapsPage from "./pages/maps/MapsPage";
import SuppliesSummary from "./pages/suppliesSummary/SuppliesSummary";

const SummaryPage = React.lazy(() => import("presentation/pages/summary"));
const DashboardPage = React.lazy(() => import("presentation/pages/dashboard"));
const OrdersPage = React.lazy(() => import("presentation/pages/orders"));
const AlarmsPage = React.lazy(() => import("presentation/pages/alarms"));
const DashboardUptimePage = React.lazy(
  () => import("presentation/pages/DashboardUptime")
);

const Router = () => {
  const session = useAppSelector((state) => state.session.session);

  return (
    <React.Suspense fallback={<LoadingPage />}>
      <BrowserRouter>
        {session && (
          <KOSFavIcon
            environment={(config.env ?? "development") as KOSEnvironment}
            companyId={session.companyId}
          />
        )}
        <ProtectedRoutes>
          <AppLayout>
            <Routes>
              <Route path="/" element={<Navigate to="/kitchen-state" />} />
              <Route path="/kitchen-state/*" element={<SummaryPage />} />
              <Route path="/dashboard/*" element={<DashboardPage />} />
              <Route path="/orders" element={<OrdersPage />} />
              <Route path="/alarms" element={<AlarmsPage />} />
              <Route path="/form/callback" element={<FormNPS />} />
              <Route path="/maps" element={<MapsPage />} />
              <Route
                path="/maps/:brand/:city/:kitchen"
                element={<MapsPage />}
              />
              <Route
                path="/dashboard-uptime"
                element={<DashboardUptimePage />}
              />
              <Route path="/supplies" element={<SuppliesSummary />} />
            </Routes>
          </AppLayout>
        </ProtectedRoutes>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default Router;
