import { ApiVersion, getHttp } from "../../common/utils/http";
import configProvider from "../../config.provider";
import { City } from "./City";

export const allCities = async (): Promise<City[]> => {
  try {
    const response = await getHttp(
      configProvider.bistroUrl,
      ApiVersion.V1,
      `city/all`
    );
    const data = await response.json();
    return data.data as City[];
  } catch (ex: any) {
    console.error(`Error getting all cities | ${ex.message}`);
    return [] as City[];
  }
};
