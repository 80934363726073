import { getCookie } from "../../../common/utils/cookie";
import { Session } from "../../../domain/entities/Session";
import { CanGetSession } from "../../../domain/interfaces/account/canGetSession";
import { TypeOfRole } from "../../../presentation/utils/role";

export class SessionCookieGateway implements CanGetSession {
  public getSession(): Session {
    const cookieUserId = getCookie("userId");
    const cookieLoginToken = getCookie("loginToken");
    const cookieRole = getCookie("role");
    const cookieCompanyId = getCookie("companyId");
    const authorization = getCookie("authorization");

    if (!cookieUserId || !cookieLoginToken) {
      throw new Error("Cookie session not found");
    }

    const session: Session = {
      userId: cookieUserId,
      token: cookieLoginToken,
      role: cookieRole ?? TypeOfRole.COCINERO_CP,
      companyId: cookieCompanyId ?? "fdgy",
      authorization: authorization ?? "",
    };

    return session;
  }
}
