import { ApiVersion, postHttp } from "../../common/utils/http";
import configProvider from "../../config.provider";
import { PaginatedStores, StoreQuery } from "./Store";

export const storesByQuery = async (
  query: StoreQuery
): Promise<PaginatedStores> => {
  try {
    const response = await postHttp(
      configProvider.bistroUrl,
      ApiVersion.V1,
      `stores/query`,
      query
    );
    const result: PaginatedStores = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getPurchaseOrders | ${ex.message}`);
    throw ex;
  }
};
